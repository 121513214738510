import { Component } from '@angular/core';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = environment.production? 'RealCare Sydney Office': 'rcs-office-dev';
  appVersion =  environment.version;

  ngOnInit(): void {
    document.title = this.title;
  };
}
